<template>
  <div
    id="notes-section-head"
    class="flex items-center justify-between"
    :class="headerClasses"
  >
    <div v-if="searchFieldOpen" class="flex items-center -mt-1">
      <div
        class="bg-gray-200 px-2 pb-1 rounded-sm -mt-2 cursor-pointer"
        :class="[isShowingDraggable ? 'ml-10' : 'ml-4']"
        @click="closeSearchInput"
      >
        <icon-base
          height="12"
          width="6"
          :icon="IconChevronRight"
          icon-color="none"
          icon-name="Hide Search"
          class="cursor-pointer transform rotate-180 bg-transparent flex justify-center items-center"
        />
      </div>
      <search-input
        v-model="searchString"
        :search-term="$t('COMMON.NOTE', { count: 2 })"
        clear-button-enabled
        class="-ml-1 mb-[10px]"
      />
    </div>
    <template v-else>
      <div
        class="pb-4 flex items-center space-x-3 min-w-0"
        :class="[
          isShowingDraggable ? 'pl-10 pr-2 grow handle cursor-grab' : 'pl-4'
        ]"
      >
        <div
          class="text-md font-semibold text-left flex truncate mb-0 space-x-1"
          :class="isShowingDraggable ? 'text-white' : 'text-headline'"
        >
          <span>{{ $t("COMMON.NOTE", { count: 2 }) }}</span>
          <div
            v-if="(isDealsList && !anyLoading) || isNotePiPModeActivated"
            class="space-x-1 truncate"
          >
            <span class="font-light">{{ $t("COMMON.FOR") }}</span>
            <span
              v-if="activeDeal.business?.business_legal_name"
              v-tooltip="{
                content: activeDeal.business.business_legal_name,
                onShow: () => !isNotePiPModeActivated
              }"
            >
              {{ activeDeal.business?.business_legal_name }}
            </span>
            <span v-else>-</span>
          </div>
        </div>
        <div
          v-if="numOfNotes && !anyLoading"
          class="rounded-full h-[17px] px-1-25 flex leading-medium text-xxs font-semibold justify-center pt-px bg-divider text-gray-800"
        >
          {{ numOfNotes }}
        </div>
        <div
          v-if="!isDealsList && !isNotePiPModeActivated"
          v-tooltip="
            isShowingDraggable
              ? $t('COMMON.SHOW_AS_SIDEBAR')
              : $t('COMMON.SHOW_AS_POPOVER')
          "
          data-cy="init-popover"
          class="px-1 cursor-pointer"
          @click="initPopover"
        >
          <icon-base :icon="IconPopup" />
        </div>
      </div>
      <div class="pr-4 pb-4 flex items-center space-x-3">
        <icon-base
          v-if="numOfNotes"
          height="16"
          width="16"
          view-box="0 0 18 18"
          :icon="IconMagnifyingGlass"
          icon-name="Search"
          class="text-gray-400 cursor-pointer"
          :class="isShowingDraggable ? 'text-white' : 'text-gray-400'"
          @click="openSearchField"
        />
        <icon-base
          v-if="isShowingDraggable && !isNotePiPModeActivated"
          data-cy="minimize-restore-notes"
          v-bind="minimizeOptions"
          width="16"
          height="16"
          class="cursor-pointer text-white"
          @click="toggleMinimized"
        />
        <icon-base
          v-if="!isNotePiPModeActivated"
          data-cy="close-notes"
          title="close-notes"
          height="16"
          width="16"
          icon="x"
          icon-color="none"
          icon-name="Close"
          class="cursor-pointer"
          :class="isShowingDraggable ? 'text-white' : 'text-gray-400'"
          @click="closeNotes"
        />
        <pip-button
          v-if="isPiPAvailable"
          :type="PipType.Note"
          :quit-pip-mode="isNotePiPModeActivated"
          @click="isPiPModeActionTriggered = true"
        />
      </div>
    </template>
  </div>
  <div
    id="main-body"
    class="pt-4 flex flex-col items-stretch"
    :class="[
      isShowingDraggable ? 'max-h-full h-full pb-14' : 'content-height',
      {
        invisible: dealNotesOptions.minimized && isShowingDraggable
      }
    ]"
  >
    <lf-content-loader :is-loading="anyLoading">
      <deal-notes-input
        class="mx-4 z-[101]"
        :note-to-edit="noteToEdit"
        :note-type="noteType"
        @edit-note:unset="setEditNote(null)"
        @type:update="updateNoteType($event)"
        @note:edited="handleNotesUpdate"
      />
    </lf-content-loader>
    <lf-content-loader
      id="notes-list"
      :is-loading="anyLoading"
      class="overflow-y-auto p-4 bg-grey-hover"
    >
      <div
        v-if="!numOfNotes"
        class="flex flex-col justify-center items-center mx-4 empty-state"
      >
        <icon-base height="70" width="70" :icon="IconNotesEmpty" />
        <lf-h3 class="mt-5">
          {{ emptyNotesStateTitle }}
        </lf-h3>
        <div class="text-center mt-3 text-base px-5">
          {{ emptyNotesStateText }}
        </div>
      </div>
      <div v-if="numOfNotes" class="flex flex-col h-screen w-full mt-2">
        <div v-if="!!notes['unread'].length" class="space-y-8">
          <div
            class="flex justify-center border-t border-red-400 overflow-visible mt-2"
          >
            <span class="px-2 bg-white -mt-2.5 text-red-400 uppercase text-xs">
              {{ $t("DEALS.DEAL_NOTES.UNREAD") }}
            </span>
          </div>
          <note-view
            v-for="note in notes['unread']"
            :id="note.id"
            :key="note.id"
            data-cy="unread-note"
            :note="note"
            :editing-note="noteToEdit?.id === note.id"
            class="unread-note rounded-md transition-colors duration-500 ease-in-out"
            @edit-note:set="setEditNote(note)"
            @edit-note:unset="setEditNote(null)"
            @note:deleted="getNotes"
          />
        </div>
        <div v-if="!!notes['read'].length" class="space-y-8">
          <div
            v-if="!!notes['unread'].length"
            class="flex justify-center border-t overflow-visible mt-2"
          >
            <span class="px-2 bg-white -mt-2.5 uppercase text-xs">
              {{ $t("DEALS.DEAL_NOTES.EARLIER") }}
            </span>
          </div>
          <note-view
            v-for="note in notes['read']"
            :id="note.id"
            :key="note.id"
            data-cy="read-note"
            :note="note"
            :editing-note="noteToEdit?.id === note.id"
            class="read-note rounded-md transition-colors duration-500 ease-in-out"
            @edit-note:set="setEditNote(note)"
            @edit-note:unset="setEditNote(null)"
            @note:deleted="getNotes"
          />
        </div>
      </div>
    </lf-content-loader>
  </div>
</template>
<script lang="ts">
import { computed, onBeforeUnmount, ref, toRaw, watch } from "vue";
import { useDeals } from "@/hooks/deals";
import type { IUser } from "@/models/users";
import { usePromiseWrapper } from "@/hooks/common";
import { useStore } from "vuex";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import { useNotification } from "@/hooks/notifications";
import { useI18n } from "vue-i18n";
import useNotesStore from "@/stores/notes";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useLocalStorageSetting } from "@/hooks/options";
import { DEFAULT_DEAL_NOTES_OPTIONS } from "@/helpers/constants/deals";
import { getEnumKeyFromValue } from "@/helpers/common";
import type { Note } from "@/models/notes";
import { NotableType, NoteState, NoteType } from "@/enums/notes";
import notesService from "@/services/modules/notes";
import { ROUTE_DEALS } from "@/router/routes";
import { usePiPView } from "@/hooks/pip";
import { PipType } from "@/enums/pip";
import isEmpty from "lodash/isEmpty";
import usePiPStore from "@/stores/pip";
</script>
<script setup lang="ts">
import DealNotesInput from "@/views/deals/components/dealNotes/DealNotesInput.vue";
import NoteView from "@/views/deals/components/dealNotes/Note.vue";
import IconSubtract from "@/components/icons/IconSubtract.vue";
import IconWidget from "@/components/icons/IconWidget.vue";
import IconPopup from "@/components/icons/IconPopup.vue";
import LfContentLoader from "@/components/ui/LfContentLoader.vue";
import IconChevronRight from "@/components/icons/IconChevronRight.vue";
import IconMagnifyingGlass from "@/components/icons/IconMagnifyingGlass.vue";
import IconNotesEmpty from "@/components/icons/IconNotesEmpty.vue";
import IconBase from "@/components/ui/IconBase.vue";
import PipButton from "@/components/deals/PipButton.vue";

const emit = defineEmits<{
  "popover:init": [
    {
      x: number;
      y: number;
    }
  ];
}>();

const props = defineProps<{
  notesLoading?: boolean;
}>();

const { getters, dispatch, commit } = useStore();
const notesStore = useNotesStore();
const pipStore = usePiPStore();
const { activeDeal: deal } = useDeals();
const route = useRoute();
const {
  currentNotes: dealNotes,
  activeNoteDealId,
  activeNoteBusinessDealId,
  isNotePiPModeActivated
} = storeToRefs(notesStore);
const { activePiPDeal } = storeToRefs(pipStore);
const { showMessage } = useNotification();
const { t } = useI18n();
const dealNotesOptions = useLocalStorageSetting(
  "dealNotesOptions",
  DEFAULT_DEAL_NOTES_OPTIONS
);
const { isPiPAvailable } = usePiPView();

const isPiPModeActionTriggered = ref(false);

const activeDeal = computed(() => activePiPDeal.value ?? deal.value);

const unreadNotesIds = computed(() =>
  notes.value["unread"].map((note) => note.id as number)
);

const { fetchWrapper: handleNotesUpdate } = usePromiseWrapper(async () => {
  if (unreadNotesIds.value.length) {
    await notesService.markNotesAsRead(unreadNotesIds.value);
  }
  await getNotes();
});

const { loading, fetchWrapper: getNotes } = usePromiseWrapper(
  async () => {
    if (isDealsList.value && !activeNoteDealId.value) {
      return;
    }

    await notesStore.getNotes({
      application_ids: [
        isDealsList.value || isNotePiPModeActivated.value
          ? (activeNoteDealId.value as string)
          : activeDeal.value.id
      ]
    });
    if (isDealsList.value) {
      dispatch("applications/getAllWithSameParams");
    }
  },
  { onError: () => showMessage(t("COMMON.ACCESS_RESTRICTED"), "error") }
);

const { loading: workflowLoading, fetchWrapper: getDealDataAndNotes } =
  usePromiseWrapper(async (id: string) => {
    await dispatch("applications/getApplication", id);
    await dispatch("workflows/getWorkflowSnapshot", id);
    getNotes();
  });

const searchFieldOpen = ref(false);
const noteType = ref<NoteType>(NoteType.Application);
const noteToEdit = ref<Note | null>(null);
const searchString = ref("");

const user = computed<IUser>(() => getters["auth/user"]);
const isDealsList = computed(() => route.name === ROUTE_DEALS);

const anyLoading = computed(
  () => loading.value || props.notesLoading || workflowLoading.value
);
const isShowingDraggable = computed(
  () => dealNotesOptions.value.state === NoteState.Draggable
);
const minimizeOptions = computed(() =>
  dealNotesOptions.value.minimized
    ? {
        icon: IconWidget,
        iconName: "Restore",
        viewBox: "0 0 16 14"
      }
    : {
        icon: IconSubtract,
        iconName: "Minimize",
        viewBox: "0 -5 20 20"
      }
);

const emptyNotesStateTitle = computed(() =>
  noteType.value === NoteType.Business
    ? t("DEALS.BUSINESS_NOTES.EMPTY_STATE_TEXT1")
    : t("DEALS.DEAL_NOTES.EMPTY_STATE_TEXT1")
);

const emptyNotesStateText = computed(() =>
  t("DEALS.DEAL_NOTES.EMPTY_STATE_TEXT2", {
    type:
      noteType.value === NoteType.Business
        ? t("COMMON.BUSINESS").toLowerCase()
        : t("COMMON.DEAL").toLowerCase(),
    group:
      noteType.value === NoteType.Business
        ? t("DEALS.DEAL_NOTES.EMPTY_STATE_TEXT3_BUSINESS")
        : t("DEALS.DEAL_NOTES.EMPTY_STATE_TEXT3")
  })
);

const notes = computed(() => {
  const searched =
    dealNotes.value?.data?.filter((note) =>
      note.comment.includes(searchString.value)
    ) || [];

  const grouped = groupBy(searched, (record) =>
    record.read_notes?.some((note) => note.user?.id === user.value?.id)
      ? "read"
      : "unread"
  );

  return {
    read: orderBy(grouped.read, "created_at")?.reverse() || [],
    unread: orderBy(grouped.unread, "created_at")?.reverse() || []
  };
});

const numOfNotes = computed(() => dealNotes.value?.data?.length || 0);

const headerClasses = computed(() => {
  if (dealNotesOptions.value.state === NoteState.PiP) {
    return "bg-grey-navigation text-white pt-4";
  }
  if (dealNotesOptions.value.state === NoteState.Static) {
    return "border-b border-gray-300/50";
  }
  let draggableClass = "bg-grey-navigation text-white pt-4";
  if (dealNotesOptions.value.minimized) {
    draggableClass += " rounded-md";
  } else {
    draggableClass += " rounded-t-md";
  }
  return draggableClass;
});

const closeNotes = () => {
  if (unreadNotesIds.value.length) {
    notesService.markNotesAsRead(unreadNotesIds.value).then(getNotes);
  }
  dealNotesOptions.value = {
    ...dealNotesOptions.value,
    state: NoteState.Closed
  };
  activeNoteDealId.value = null;
};

const toggleMinimized = () => {
  dealNotesOptions.value = {
    ...dealNotesOptions.value,
    minimized: !dealNotesOptions.value.minimized
  };
};

const closeSearchInput = () => {
  searchString.value = "";
  searchFieldOpen.value = false;
};

const initPopover = (event: MouseEvent) => {
  dealNotesOptions.value = {
    ...dealNotesOptions.value,
    state:
      dealNotesOptions.value.state === NoteState.Draggable
        ? NoteState.Static
        : NoteState.Draggable
  };
  if (dealNotesOptions.value.state === NoteState.Draggable) {
    emit("popover:init", { x: event.x, y: event.y });
  }
};

const setEditNote = (note: Note | null) => {
  noteToEdit.value = note;
  if (!note) {
    return;
  }
  const notableType = note.notable_type;
  if (notableType === getEnumKeyFromValue(NotableType, NoteType.Business)) {
    noteType.value = NoteType.Business;
    return;
  } else if (notableType === getEnumKeyFromValue(NotableType, NoteType.Call)) {
    noteType.value = NoteType.Call;
    return;
  }
  noteType.value = NoteType.Application;
};

const openSearchField = () => {
  searchFieldOpen.value = true;
  dealNotesOptions.value = {
    ...dealNotesOptions.value,
    minimized: false
  };
};

const updateNoteType = (type: NoteType) => {
  noteType.value = type;
  activeNoteBusinessDealId.value =
    type === NoteType.Business ? activeDeal.value.business.id : null;
};

watch(
  dealNotesOptions,
  (newValue, oldValue) => {
    const hasNotesToBeMarkedAsRead = !!notes.value["unread"].length;
    if (hasNotesToBeMarkedAsRead) {
      if (newValue.state !== NoteState.Closed) {
        const noteIds = notes.value["unread"].map((note) => note.id as number);
        notesService.markNotesAsRead(noteIds);
      }
      if (oldValue && !newValue) {
        getNotes();
      }
    }
    if (newValue.state === NoteState.Closed) {
      setEditNote(null);
    }
  },
  { deep: true }
);

watch(
  activeNoteDealId,
  async (newId) => {
    if (newId) {
      await getDealDataAndNotes(newId);
    }
  },
  { immediate: true }
);

watch(
  [isNotePiPModeActivated, deal],
  ([isPiPActivated, updatedDeal]) => {
    if (isPiPActivated && !isEmpty(updatedDeal) && !activePiPDeal.value) {
      activePiPDeal.value = structuredClone(toRaw(deal.value));
      activeNoteDealId.value = activePiPDeal.value.id;
    }
  },
  { immediate: true }
);

onBeforeUnmount(() => {
  if (isDealsList.value && !isPiPModeActionTriggered.value) {
    activeNoteDealId.value = null;
    commit("applications/unsetActive");
  }
});
</script>
<style scoped>
.content-height {
  height: 97%;
}

#notes-list {
  box-shadow: 0 15px 30px -36px gray inset !important;
}
</style>
<style>
.empty-state {
  @apply h-full;
}

.resizable-component {
  container-type: size;
}

@container (max-height: 430px) {
  .empty-state {
    height: calc(100% - 130px);
  }
  .empty-state svg {
    display: none !important;
  }
}

@container (max-height: 350px) {
  .empty-state h3,
  .empty-state div {
    @apply text-xs leading-normal mt-0;
  }
}
</style>
